@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  background-color: black;
}

.no-scroll-bars {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.no-scroll-bars::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.press-start{
  font-family: 'Press Start 2P', cursive;
}

.App-link {
  color: rgb(255, 204, 51, 1);
  font-family: 'Press Start 2P', cursive;
  text-decoration-color: #cd3333;
cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
